<template>
  <div
    class="col-lg-6 col-12 info_text wow animate__animated animate__fadeInUp"
    data-wow-duration="2s"
  >
    <h1 class="my-3">{{ data.text.title }}</h1>
    <div class="line_bottom"></div>
    <p class="mt-3 mb-3">{{ data.text.content }}</p>
    <b-button variant="primary" size="lg" v-if="data.text.button_link">
      <router-link :to="data.text.button_link">more</router-link>
    </b-button>
  </div>
</template>
<script>
export default {
  props: {
    data: Object,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.hi8APP {
  .info_text {
    margin: auto 0rem;
  }
}
.marketing,
.hi8APP {
  .carousel.slide {
    max-width: 380px;
    margin: auto;
  }
}
.info_text {
  z-index: 99;
  margin-top: -3rem;
  padding: 3rem 2rem;
  max-width: 512px;
  max-height: 414px;
  background-color: #ffffff;
  box-shadow: 27px 34px 42px 0px rgb(0 0 0 / 20%);
  text-align: right;
  h1,
  p {
    text-align: left;
  }
  h1 {
    text-align: left;
    font-size: 2.6rem;
  }
  button {
    box-shadow: 8px 12px 20px 0px rgb(0 0 0 / 40%);
  }
}
section:not(.marketing, .hi8APP, .banner) {
  img {
    margin: 0rem -9rem;
    padding: 0rem 0;
    width: 160%;
    max-width: 900px;
  }
}

.info_text {
  h1 {
    font-size: 2.6rem;
    font-weight: 600;
  }
  p {
    width: 70%;
    margin: auto;
    line-height: 2rem;
  }
}
@media screen and (max-width: 989px) {
  section:not(.marketing, .hi8APP, .banner) {
    img {
      width: 107%;
    }
  }
  .hi8APP {
    img {
      width: 60%;
    }
  }
  .info_text {
    padding: 1.5rem;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 27px 34px 42px 0px rgb(0 0 0 / 20%);
  }
  .info_text p {
    width: 99%;
    margin: auto;
    line-height: 2rem;
  }
}
</style>
