import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { title: "首頁-翰成數位科技" },
  },
  {
    path: "/about",
    name: "about",
    meta: { title: "關於我們-翰成數位科技" },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/marketing",
    name: "marketing",
    meta: { title: "整合行銷-翰成數位科技" },
    // route level code-splitting
    // this generates a separate chunk (marketing.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "marketing" */ "../views/Marketing.vue"),
  },
  {
    path: "/hce",
    name: "hce",
    meta: { title: "HCE娛樂-翰成數位科技" },
    // route level code-splitting
    // this generates a separate chunk (hce.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hce" */ "../views/Hce.vue"),
  },
  {
    path: "/hi8app",
    name: "hi8app",
    meta: { title: "嗨吧APP-翰成數位科技" },
    // route level code-splitting
    // this generates a separate chunk (hi8app.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "hi8app" */ "../views/Hi8app.vue"),
  },
  {
    path: "/hiSchool",
    name: "hiSchool",
    meta: { title: "嗨學院-翰成數位科技" },
    // route level code-splitting
    // this generates a separate chunk (hiSchool.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "hiSchool" */ "../views/HiSchool.vue"),
  },
  {
    path: "/brands",
    name: "brands",
    meta: { title: "品牌介紹-翰成數位科技" },
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Brands.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    meta: { title: "聯絡我們-翰成數位科技" },
    // route level code-splitting
    // this generates a separate chunk (contact.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/private",
    name: "private",
    meta: { title: "隱私權政策-翰成數位科技" },
    // route level code-splitting
    // this generates a separate chunk (private.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "private" */ "../views/Private.vue"),
  },
  {
    path: "/eventRegistration",
    name: "event",
    meta: { title: "活動報名" },
    // route level code-splitting
    // this generates a separate chunk (private.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "private" */ "../views/Event.vue"),
  },
];

const router = new VueRouter({
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
