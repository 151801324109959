<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="4000"
      background="#000"
      style="text-shadow: 1px 1px 2px #000"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide
        v-for="(item, key) in img"
        :key="key"
        :img-src="item.url"
      >
        <div
          class="wow animate__animated animate__fadeInUp"
          data-wow-delay="1s"
          v-html="item.text"
        ></div>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>
<script>
export default {
  props: {
    img: Array,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  mounted() {},
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>
