<template>
  <div class="col-xl-9 col-lg-11 mx-auto row">
    <div class="col-md-2">
      <img alt="hanchu logo" :src="require('../assets/footer_logo.svg')" />
      <p style="font-size: 0.8rem">Copyright @2021 Hanchu.com.tw, Inc</p>
      <!-- <p>Powered by Art Tangency Creation</p> -->
    </div>
    <div class="col-md-10 row right_site">
      <div class="col-sm col-6 my-3">
        <b class="my-2">
          <router-link to="/about">關於翰成</router-link>
        </b>
        <router-link to="/about#history">歷史沿革</router-link>
        <router-link to="/about#history">里程碑</router-link>
        <router-link to="/about#AI">AI 數據發展</router-link>
      </div>
      <div class="col-sm col-6 my-3">
        <b class="my-2">
          <router-link to="/marketing">整合行銷</router-link>
        </b>
        <router-link to="/marketing#brand">品牌運營</router-link>
        <router-link to="/marketing#product">產品代操</router-link>
        <router-link to="/marketing#stream">直播專場</router-link>
        <router-link to="/marketing#chain">短影音創作</router-link>
      </div>
      <div class="col-sm col-6 my-3">
        <b class="my-2">
          <router-link to="/hce">HCE 娛樂</router-link>
        </b>
        <router-link to="/hce#MCN">MCN、KOL合作</router-link>
        <router-link to="/hce#multimedia">多媒體創作</router-link>
        <router-link to="/hce#planning">企劃製作</router-link>
        <router-link to="/hce#rental">直播攝影棚租賃</router-link>
      </div>
      <div class="col-sm col-6 my-3">
        <b class="my-2">
          <router-link to="/hi8APP">嗨吧APP</router-link>
        </b>
        <router-link to="/hi8APP#eight_elements">推廣項目</router-link>
        <router-link to="/hi8APP#connectLife">短影音直播</router-link>
        <router-link to="/hi8APP#connectLife">營運內容</router-link>
        <router-link to="/hi8APP#competitiveness">AI 數據</router-link>
      </div>
      <div class="col-sm col-6 my-3">
        <b class="my-2">
          <router-link to="/hiSchool">嗨學院</router-link>
        </b>
        <router-link to="/hiSchool#train">企業培訓</router-link>
        <router-link to="/hiSchool#subject">數位課程</router-link>
        <router-link to="/hiSchool#teachers">專業師資</router-link>
        <router-link to="/hiSchool#lease">多元空間租賃</router-link>
      </div>
      <div class="col-12 row footer_footer" style="justify-content: flex-end">
        <div class="col-12 col-sm-8 col-xl-7 row">
          <div class="my-1 my-lg-0 col-sm border-left border-right">
            <router-link to="/contact">聯絡我們</router-link>
          </div>
          <div class="my-1 my-lg-0 col-sm border-right">
            <router-link to="/private">隱私權政策</router-link>
          </div>
          <div class="my-1 my-lg-0 col-sm border-right">
            <a href="https://www.104.com.tw/company/ck0s028" target="_blank"
              >加入翰成數位</a
            >
          </div>
        </div>
        <!-- <div class="col-6 mx-auto mx-lg-0 col-lg-3 row">
            <i class="col fab fa-facebook fa-2x"></i>
            <i class="col fab fa-youtube fa-2x"></i>
            <i class="col fab fa-instagram fa-2x"></i>
          </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
img {
  margin-bottom: 5rem;
  max-width: 156px;
}
.footer_footer {
  margin-top: 3rem;
}
.right_site {
  padding-left: 5rem;
}
.border-left {
  border-left: 1px solid white;
  height: 1rem;
  a {
    margin: auto;
  }
}
.border-right {
  border-right: 1px solid white;
  padding: 0 1rem;
  height: 1rem;
  a {
    margin: auto;
  }
}
a {
  margin: 0.5rem 0;
  color: white;
  text-decoration: none;
  display: block;
  &:hover {
    color: #2575ed;
  }
}
@media screen and( max-width: 989px) {
  img {
    margin-bottom: 2rem;
  }
  .right_site {
    padding-left: unset;
  }
}
</style>
