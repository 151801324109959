<template>
  <div class="home">
    <section
      class="banner wow animate__animated animate__zoomIn"
      data-wow-duration="3s"
    >
      <router-link to="About">
        <Carousel :img="banner_data.carousel" />
      </router-link>
    </section>
    <section class="marketing">
      <div class="row col-xl-9 col-11 mx-auto p-3">
        <Info :data="marketing_data" />
        <div
          class="col-lg-6 col-12 my-auto wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <Carousel :img="marketing_data.carousel" />
        </div>
      </div>
    </section>
    <section class="hi8APP">
      <div class="row col-xl-9 col-11 mx-auto p-3">
        <Info :data="hi8APP_data" />
        <div
          class="col-lg-6 col-12 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <img src="@/assets/messageImage_1642642163957.jpg" />
        </div>
      </div>
    </section>
    <section class="hce">
      <div class="row col-xl-9 col-11 mx-auto p-3">
        <Info :data="hce_data" />
        <div
          class="col-lg-6 col-12 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <img
            src="@/assets/home_HCE.png"
            alt=""
          />
        </div>
      </div>
    </section>
    <section class="hiSchool">
      <div class="row col-xl-9 col-11 mx-auto p-3">
        <Info :data="hiSchool_data" />
        <div
          class="col-lg-6 col-12 wow animate__animated animate__fadeInUp"
          data-wow-duration="1s"
        >
          <img
            src="@/assets/home_HiSchool.jpg"
            alt=""
          />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// @ is an alias to /src
import Carousel from "@/components/Carousel";
import Info from "@/components/Info";

export default {
  components: {
    Carousel,
    Info,
  },
  data() {
    return {
      banner_data: {
        carousel: [
          {
            url: require("../assets/nathan-dumlao-hanc_banner.jpg"),
            text: `<div class="home_banner_text">Creativity<br>
                  Drives<br>
                  Business Innovation<br>
                  And Growth</div> 
                  <h3>創造力帶動新商機</h3>
                  `,
          },
        ],
      },
      marketing_data: {
        carousel: [
          {
            url: require("../assets/home/logo_nippi_380x380.svg"),
            text: ``,
          },
          {
            url: require("../assets/home/logo_hcbeauty_380x380.svg"),
            text: ``,
          },
          {
            url: require("../assets/home/logo_hcpure_380x380.svg"),
            text: ``,
          },
          {
            url: require("../assets/home/logo_aron_380x380.svg"),
            text: ``,
          },
          {
            url: require("../assets/home/logo_hischool_380x380.svg"),
            text: ``,
          },
          {
            url: require("../assets/home/logo_hiyuoguo_380x380.svg"),
            text: ``,
          },
          {
            url: require("../assets/home/logo_hibar_380x380.svg"),
            text: ``,
          },
          {
            url: require("../assets/home/logo_inscrush_380x380.svg"),
            text: ``,
          },
        ],
        text: {
          title: "整合行銷",
          content: `翰成數位擁有豐富國際貿易、品牌運營、直播專場、短影音帶貨、產品代操作、供應鏈整合經驗、我們竭誠歡迎優良廠商合作共創商機`,
          button_link: "/marketing",
        },
      },
      hi8APP_data: {
        text: {
          title: "嗨吧 APP",
          content: `短影音直播是未來新媒體最大商機、每個用戶、商家、企業將透過短影音直播建立 – 創造、娛樂、音樂、購物、新聞、搜尋、社交、商機 八大元素關聯性、嗨吧以八元素為核心創造未來美好生活。`,
          button_link: "/hi8APP",
        },
      },
      hce_data: {
        text: {
          title: "HCE娛樂",
          content: `翰成數位 HCE 直播產業基地占地 1314 坪、基地建置 10 間攝影棚、專攻直播短影音業務建構台灣直播短影音生態圈、孵化直播短影音專業人才。`,
          button_link: "/hce",
        },
      },
      hiSchool_data: {
        text: {
          title: "嗨學院",
          content: `我們擁有直播電商、短影音創作、造型穿搭、口條發音、文案企劃、拍攝剪輯、音樂創作、KOL 經紀、全台灣最堅強師資。`,
          button_link: "/hiSchool",
        },
      },
    };
  },
};
</script>

<style lang="scss">
.home {
  .row {
    justify-content: center;
  }
  .marketing #carousel-1,
  .carousel-item {
    background: unset !important;
  }
  .hce {
    background-image: linear-gradient(180deg, #f3f3f3 44%, #070a11 44%);
  }
  .hi8APP {
    img {
      border-radius: 10%;
    }
  }
  section {
    padding: 5rem 0;
  }
  section:not(.marketing, .hi8APP, .hce) {
    background-color: #070a11;
  }
  .banner {
    padding: 0;
    .carousel-caption {
      position: absolute;
      bottom: 7.25rem;
      top: 15%;
      left: 10%;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      color: #fff;
      text-align: left;
      text-shadow: 0px 0px 10px rgb(0 0 0 / 30%);
      .home_banner_text {
        font-size: 4rem;
      }
      h3 {
        font-size: 2rem;
      }
    }
  }
  @media screen and (min-width: 501px) and (max-width: 989px) {
    .banner {
      .carousel-caption {
        .home_banner_text {
          font-size: 2rem;
        }
        h3 {
          font-size: 1rem;
        }
      }
    }
  }
  @media screen and (max-width: 500px) {
    .banner {
      .carousel-caption {
        .home_banner_text {
          font-size: 1rem;
        }
        h3 {
          font-size: 0.5rem;
        }
      }
    }
  }
}
</style>
