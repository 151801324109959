<template>
  <div id="app">
    <div id="nav">
      <Navbar />
    </div>
    <div id="router_content">
      <router-view />
    </div>
    <div id="footer">
      <Footer />
    </div>
  </div>
</template>
<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
export default {
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  background: #f3f3f3;
}
a {
  color: white;
  text-decoration: none;
  &:hover {
    color: #2575ed;
  }
}

.line_bottom {
  border-bottom: 2px solid #000;
  width: 36px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .first_section {
    padding-top: 15rem;
  }
  section:not(.contact section, .home .banner) {
    padding-bottom: 15rem;
  }
  @media screen and (min-width: 501px) and (max-width: 989px) {
    h1 {
      font-size: 2rem;
    }
    .first_section {
      padding-top: 5rem;
    }
    section:not(.contact section, .home .banner) {
      padding-bottom: 10rem;
    }
  }
  @media screen and (max-width: 501px) {
    h1 {
      font-size: 2rem;
    }
    .first_section {
      padding-top: 12rem;
    }
    section:not(.contact section, .home .banner) {
      padding-bottom: 10rem;
    }
  }
}
#nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
#nav,
#footer {
  .col-xl-9 {
    padding: 5rem 0;
    color: white;
  }
  width: 100%;
  &,
  .bg-info {
    background-color: #1e222d !important;
  }
  #nav-collapse {
    justify-content: flex-end;
  }
}
#router_content {
  padding-top: 88px;
  a{
    color: #2575ED;
  }
}
</style>
