<template>
  <div class="col-xl-9 col-lg-11 col-11 mx-auto py-2">
    <b-navbar toggleable="lg" type="dark" variant="info">
      <b-navbar-brand>
        <router-link to="/">
          <img alt="hanchu logo" :src="require('../assets/navbar_logo.png')" />
        </router-link>
      </b-navbar-brand>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item>
            <router-link to="/about">關於我們</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/marketing">整合行銷</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/hce">HCE娛樂</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/hi8APP">嗨吧 APP</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/hiSchool">嗨學院</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/brands">品牌介紹</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link to="/contact">聯絡我們</router-link>
          </b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.nav-item {
  margin: 0 0.5rem;
}
.router-link-active {
  color: #2575ed;
}
@media screen and( max-width: 990px) {
  img {
    width: 190px;
  }
}
</style>
